import gsap from "gsap";
import imagesloaded from "imagesloaded";

export default {
  data() {
    return {
      timer: null,
    };
  },
  methods: {
    LoadImage() {
      const percent_rects = document.querySelectorAll(
        "#ImageLoader .percent_rect"
      );
      gsap.set(percent_rects, {
        fill: "transparent",
        fillOpacity: "0",
      });
      let vm = this;
      //鎖住body
      document.body.style.overflowY = "hidden";
      document.body.style.position = "fixed";
      //初始化
      const images = gsap.utils.toArray("img");
      const loader = document.querySelector(".loader--text");
      loader.innerHTML = "0%";
      this.$store.commit("SetImageLoaded", false);

      //進度條
      const updateProgress = (instance) => {
        loader.innerHTML = `${Math.round(
          (instance.progressedCount * 100) / images.length
        )}%`;
        vm.LoadGsap(
          Math.round((instance.progressedCount * 100) / images.length)
        );
      };

      //動畫
      this.LoadGsap();

      //完成事件
      const showDemo = () => {
        //復原body
        if (this.$route.name != "customize") {
          document.body.style.position = "relative";
          document.body.style.overflowY = "auto";
        }
        window.scrollTo(0, 0);

        //設定讀取完成
        this.$store.commit("SetImageLoaded", true);
        // console.log('all images have been loaded!');

        //關閉讀取畫面
        let image_loader = document.querySelector("#ImageLoader");
        clearTimeout(this.timer);
        let time_line = gsap.timeline();
        time_line.fromTo(
          image_loader,
          {
            opacity: 1,
          },
          {
            opacity: 0,
            direction: 0.3,
          }
        );
        time_line.set(image_loader, {
          display: "none",
          onComplete: () => {
            loader.innerHTML = "0%";
            const percent_rects = document.querySelectorAll(
              "#ImageLoader .percent_rect"
            );
            gsap.to(percent_rects, {
              fill: "#00C1B5",
              fillOpacity: "0",
              duration: 0.3,
            });
          },
        });
      };

      imagesloaded(images)
        .on("progress", updateProgress)
        .on("always", showDemo);
    },
    LoadGsap(progress_percent) {
      // clearTimeout(this.timer);
      const percent_rects = document.querySelectorAll(
        "#ImageLoader .percent_rect"
      );
      let max_count = Math.ceil(progress_percent / 7.6);
      percent_rects.forEach((item, item_index) => {
        if (item_index <= max_count) {
          gsap.to(item, {
            fill: "#00C1B5",
            fillOpacity: ".6",
            duration: 0.3,
          });
        }
      });
    },
    ResetLoadImage() {
      let image_loader = document.querySelector("#ImageLoader");
      let time_line = gsap.timeline();
      time_line.set(image_loader, {
        display: "flex",
      });
      time_line.fromTo(
        image_loader,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 0,
        }
      );
    },
  },
};
