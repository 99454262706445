var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"text-main-color text-center",on:{"mouseenter":function($event){_vm.hover = true},"mouseleave":function($event){_vm.hover = false},"click":_vm.ScrollTop}},[_c('div',{staticClass:"relative w-14 h-14 flex justify-center items-center mx-auto mb-2"},[_c('arrow_upward',{staticClass:"text-2xl text-main-color"}),_c('span',{staticClass:"absolute top-0 left-0 z-10 transform-gpu transition-all duration-300",class:_vm.hover
          ? 'translate-x-1 translate-y-1'
          : '-translate-x-0 -translate-y-0 '},[_c('i',{staticClass:"block w-3 h-[2px] bg-main-color"}),_c('i',{staticClass:"block w-[2px] h-2 bg-main-color"})]),_c('span',{staticClass:"absolute -top-0 -right-0 z-10 transform-gpu transition-all duration-300 flex flex-col items-end",class:_vm.hover
          ? '-translate-x-1 translate-y-1 '
          : 'translate-x-0 -translate-y-0 '},[_c('i',{staticClass:"block w-3 h-[2px] bg-main-color"}),_c('i',{staticClass:"block w-[2px] h-2 bg-main-color"})]),_c('span',{staticClass:"absolute -bottom-0 -left-0 z-10 transform-gpu transition-all duration-300",class:_vm.hover
          ? 'translate-x-1 -translate-y-1 '
          : '-translate-x-0 translate-y-0 '},[_c('i',{staticClass:"block w-[2px] h-2 bg-main-color"}),_c('i',{staticClass:"block w-3 h-[2px] bg-main-color"})]),_c('span',{staticClass:"absolute -bottom-0 -right-0 z-10 transform-gpu transition-all duration-300 flex flex-col items-end",class:_vm.hover
          ? '-translate-x-1 -translate-y-1 '
          : 'translate-x-0 translate-y-0 '},[_c('i',{staticClass:"block w-[2px] h-2 bg-main-color"}),_c('i',{staticClass:"block w-3 h-[2px] bg-main-color"})])],1),_c('p',{staticClass:"font-audiowide text-sm text-main-color mx-auto text-center"},[_vm._v(" PageTop ")])])}
var staticRenderFns = []

export { render, staticRenderFns }