import gsap from 'gsap';

export class menu {
  constructor(el) {
    this.el = el;
    this.left_panel = el.querySelector('[data-panel-left]');
    this.right_panel = el.querySelector('[data-panel-right]');
    this.logo = el.querySelectorAll('[data-panel-logo]');
    this.menu_item = el.querySelectorAll('[data-panel-link]');
    this.frame = el.querySelectorAll('[data-frame]');

    this.timeline = null;
  }

  open() {
    this.timeline != null ? this.timeline.kill() : '';
    this.timeline = gsap.timeline();

    this.timeline
      .set(
        this.el,
        {
          x: 0,
        },
        'same'
      )
      .fromTo(
        this.left_panel,
        {
          y: '100%',
        },
        {
          y: '0%',
          delay: 0.3,
        },
        'same'
      )
      .fromTo(
        this.right_panel,
        {
          y: '-100%',
        },
        {
          y: '0%',
          delay: 0.3,
        },
        'same'
      )
      .fromTo(
        this.frame,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 1,
          delay: 0.6,
        },
        'same'
      )
      .fromTo(
        this.logo,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          delay: 0.6,
        },
        'same'
      )
      .fromTo(
        this.logo[1],
        {
          x: '-50%',
          y: '-50%',
        },
        {
          x: '-52%',
          y: '-48%',
          delay: 0.8,
        },
        'same'
      )
      .fromTo(
        this.logo[2],
        {
          x: '-50%',
          y: '-50%',
        },
        {
          x: '-48%',
          y: '-52%',
          delay: 0.8,
        },
        'same'
      )
      .fromTo(
        this.menu_item,
        {
          y: '100%',
        },
        {
          y: '0%',
          delay: 0.8,
          stagger: 0.1,
        },
        'same'
      );
  }
  close() {
    this.timeline != null ? this.timeline.kill() : '';
    this.timeline = gsap.timeline();

    this.timeline
      .fromTo(
        this.left_panel,
        {
          y: '0%',
        },
        {
          y: '100%',
          delay: 0.6,
        },
        'first'
      )
      .fromTo(
        this.right_panel,
        {
          y: '0%',
        },
        {
          y: '-100%',
          delay: 0.6,
        },
        'first'
      )
      .fromTo(
        this.frame,
        {
          opacity: 1,
        },
        {
          opacity: 0,
          delay: 0.4,
        },
        'first'
      )
      .fromTo(
        this.logo,
        {
          opacity: 1,
        },
        {
          opacity: 0,
        },
        'first'
      )
      .fromTo(
        this.logo[1],
        {
          x: '-52%',
          y: '-48%',
        },
        {
          x: '-50%',
          y: '-50%',
        },
        'first'
      )
      .fromTo(
        this.logo[2],
        {
          x: '-48%',
          y: '-52%',
        },
        {
          x: '-50%',
          y: '-50%',
        },
        'first'
      )
      .fromTo(
        this.menu_item,
        {
          y: '0%',
        },
        {
          y: '100%',
        },
        'first'
      )
      .set(
        this.el,
        {
          x: '-110%',
          delay: 0.3,
        },
        'second'
      );
  }
}
