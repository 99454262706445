import { gsap } from '@/gsap/gsap_loader';

export class home_cover_animation {
  constructor(el) {
    this.el = el;
    this.title = el.querySelector('[data-title]');
    this.image = el.querySelectorAll('[data-image]');
    this.bg_cover = el.querySelector('[data-image-cover]');
    this.marquee = el.querySelector('[data-marquee]');

    this.timeline = null;
    this.init();
  }

  init() {
    this.timeline != null ? this.timeline.kill() : '';
    this.timeline = gsap.timeline();

    this.timeline
      .fromTo(
        this.bg_cover,
        {
          x: '-110%',
          opacity: 1,
        },
        {
          x: '0%',
          opacity: 1,
          delay: 0.4,
          duration: 0.8,
          ease: 'power4.out',
        },
        'first'
      )
      .fromTo(
        this.image,
        {
          x: '-100%',
          opacity: 0,
        },
        {
          x: '0%',
          opacity: 0,
          delay: 0.4,
          duration: 0.8,
          ease: 'power4.out',
        },
        'first'
      )
      .set(
        this.image,
        {
          opacity: 1,
        },
        'second'
      )
      .fromTo(
        this.bg_cover,
        {
          x: '0%',
          opacity: 1,
        },
        {
          x: '110%',
          opacity: 1,
          duration: 0.8,
          ease: 'power4.inOut',
        },
        'third'
      )
      .fromTo(
        this.title,
        {
          x: '-110%',
          opacity: 0,
        },
        {
          x: '0%',
          opacity: 1,
          delay: 0.4,
          duration: 0.8,
          ease: 'power4.out',
        },
        'third'
      )
      .fromTo(
        this.marquee,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 0.8,
          delay: 0.4,
          ease: 'power4.out',
        },
        'forth'
      );
  }
}
