<template>
  <button
    class="text-main-color text-center"
    @mouseenter="hover = true"
    @mouseleave="hover = false"
    @click="ScrollTop"
  >
    <div
      class="relative w-14 h-14 flex justify-center items-center mx-auto mb-2"
    >
      <arrow_upward class="text-2xl text-main-color" />

      <span
        class="absolute top-0 left-0 z-10 transform-gpu transition-all duration-300"
        :class="
          hover
            ? 'translate-x-1 translate-y-1'
            : '-translate-x-0 -translate-y-0 '
        "
      >
        <i class="block w-3 h-[2px] bg-main-color"></i>
        <i class="block w-[2px] h-2 bg-main-color"></i>
      </span>
      <span
        class="absolute -top-0 -right-0 z-10 transform-gpu transition-all duration-300 flex flex-col items-end"
        :class="
          hover
            ? '-translate-x-1 translate-y-1 '
            : 'translate-x-0 -translate-y-0 '
        "
      >
        <i class="block w-3 h-[2px] bg-main-color"></i>
        <i class="block w-[2px] h-2 bg-main-color"></i>
      </span>
      <span
        class="absolute -bottom-0 -left-0 z-10 transform-gpu transition-all duration-300"
        :class="
          hover
            ? 'translate-x-1 -translate-y-1 '
            : '-translate-x-0 translate-y-0 '
        "
      >
        <i class="block w-[2px] h-2 bg-main-color"></i>
        <i class="block w-3 h-[2px] bg-main-color"></i>
      </span>
      <span
        class="absolute -bottom-0 -right-0 z-10 transform-gpu transition-all duration-300 flex flex-col items-end"
        :class="
          hover
            ? '-translate-x-1 -translate-y-1 '
            : 'translate-x-0 translate-y-0 '
        "
      >
        <i class="block w-[2px] h-2 bg-main-color"></i>
        <i class="block w-3 h-[2px] bg-main-color"></i>
      </span>
    </div>
    <p class="font-audiowide text-sm text-main-color mx-auto text-center">
      PageTop
    </p>
  </button>
</template>

<script>
import arrow_upward from '@/components/svg/arrow_upward';
export default {
  name: 'TopButton',
  components: {
    arrow_upward,
  },
  data() {
    return {
      hover: false,
    };
  },
  methods: {
    ScrollTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
  },
};
</script>
