<template>
  <div class="absolute hidden">
    <ol itemscope itemtype="https://schema.org/BreadcrumbList">
      <template v-for="(item, item_index) in path">
        <li
          itemprop="itemListElement"
          itemscope
          itemtype="https://schema.org/ListItem"
          :key="`path_${item_index}_link`"
        >
          <router-link
            itemscope
            itemtype="https://schema.org/WebPage"
            itemprop="item"
            :itemid="`https://en.bikepow.com${item.link}`"
            :to="item.link"
          >
            <span itemprop="name">
              {{ item.title }}
            </span>
          </router-link>
          <meta itemprop="position" :content="item_index + 1" />
        </li>
      </template>
    </ol>
  </div>
</template>
<script>
export default {
  name: 'BreadCrumb',
  props: {
    path: {
      require: true,
      type: Array,
    },
  },
};
</script>
