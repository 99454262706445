import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import VueMeta from 'vue-meta';
Vue.use(VueMeta, {
  refreshOnceOnNavigation: false,
});
Vue.config.productionTip = false;

// image loader
import image_loaded from './gsap/image_loaded';
Vue.mixin(image_loaded);

// return error page
import prerender_event from './common/prerender_event';
Vue.mixin(prerender_event);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
