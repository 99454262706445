<template>
  <button
    class="main_button"
    :class="`${theme} ${border_reverse}`"
    @mouseenter="hover = true"
    @mouseleave="hover = false"
    @click="$emit('click')"
  >
    <svg
      class="icon block w-2 mr-3"
      :class="hover ? 'hover' : ''"
      data-v-3e12140e=""
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 6 6"
    >
      <path d="M5.83 3L.17.89v4.22z"></path>
    </svg>

    {{ text }}
  </button>
</template>

<script>
export default {
  name: "MainButton",
  props: {
    border_reverse: {
      type: String,
      default: "",
    },
    theme: {
      type: String,
      default: "default",
    },
    text: String,
  },
  data() {
    return {
      hover: false,
    };
  },
  computed: {
    button_class() {
      let class_name = this.border_reverse
        ? `bg-transparent hover:bg-[${this.theme}] text-[${this.theme}] hover:text-white`
        : `bg-[${this.theme}] hover:bg-transparent text-white hover:text-[${this.theme}]`;
      class_name += ` border-[${this.theme}]`;
      return class_name;
    },
  },
};
</script>