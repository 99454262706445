var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contact_btn relative cursor-pointer select-none",on:{"mouseenter":function($event){_vm.hover = true},"mouseleave":function($event){_vm.hover = false},"click":function($event){return _vm.$router.push('/Contact')}}},[_c('span',{staticClass:"\n      absolute\n      -top-2\n      -left-2\n      z-10\n      transform-gpu\n      transition-all\n      duration-300\n    ",class:_vm.hover
        ? 'translate-x-0 translate-y-0 opacity-100'
        : '-translate-x-4 -translate-y-4 opacity-0'},[_c('i',{staticClass:"block w-3 h-[2px] bg-black"}),_c('i',{staticClass:"block w-[2px] h-2 bg-black"})]),_c('span',{staticClass:"\n      absolute\n      -top-2\n      -right-2\n      z-10\n      transform-gpu\n      transition-all\n      duration-300\n      flex flex-col\n      items-end\n    ",class:_vm.hover
        ? 'translate-x-0 translate-y-0 opacity-100'
        : 'translate-x-4 -translate-y-4 opacity-0'},[_c('i',{staticClass:"block w-3 h-[2px] bg-black"}),_c('i',{staticClass:"block w-[2px] h-2 bg-black"})]),_c('span',{staticClass:"\n      absolute\n      -bottom-2\n      -left-2\n      z-10\n      transform-gpu\n      transition-all\n      duration-300\n    ",class:_vm.hover
        ? 'translate-x-0 translate-y-0 opacity-100'
        : '-translate-x-4 translate-y-4 opacity-0'},[_c('i',{staticClass:"block w-[2px] h-2 bg-black"}),_c('i',{staticClass:"block w-3 h-[2px] bg-black"})]),_c('span',{staticClass:"\n      absolute\n      -bottom-2\n      -right-2\n      z-10\n      transform-gpu\n      transition-all\n      duration-300\n      flex flex-col\n      items-end\n    ",class:_vm.hover
        ? 'translate-x-0 translate-y-0 opacity-100'
        : 'translate-x-4 translate-y-4 opacity-0'},[_c('i',{staticClass:"block w-[2px] h-2 bg-black"}),_c('i',{staticClass:"block w-3 h-[2px] bg-black"})]),_c('div',{staticClass:"overflow-hidden relative rounded-md px-10 py-2"},[_c('p',{staticClass:"\n        font-audiowide\n        w-full\n        text-center\n        lg:text-8xl\n        sm:text-6xl\n        text-4xl\n        absolute\n        z-10\n        left-1/2\n        -translate-x-1/2\n        transition-transform\n        transform-gpu\n        duration-300\n        text-main-color\n      ",class:_vm.hover ? 'top-1/2 -translate-y-1/2' : '-top-1/2 -translate-y-[200%]'},[_vm._v(" CONTACT US ")]),_c('p',{staticClass:"\n        font-audiowide\n        lg:text-8xl\n        sm:text-6xl\n        text-4xl\n        relative\n        z-10\n        transition-transform\n        transform-gpu\n        duration-300\n      ",class:_vm.hover ? 'translate-y-[110%]' : 'translate-y-0'},[_vm._v(" CONTACT US ")]),_c('div',{staticClass:"\n        absolute\n        top-0\n        left-0\n        bottom-0\n        right-0\n        z-0\n        bg-zinc-200\n        origin-bottom\n        transition-transform\n        transform-gpu\n        duration-300\n      ",class:_vm.hover ? 'scale-y-0' : 'scale-y-1'})])])}
var staticRenderFns = []

export { render, staticRenderFns }