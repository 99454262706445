<template>
  <section id="HomeNews" ref="MainContent" class="relative z-10 w-full pb-20">
    <div
      class="relative z-10 flex flex-wrap items-end justify-between max-w-screen-xl px-10 pt-20 pb-10 mx-auto mb-10 md:pt-32 lg:px-40 sm:px-20 sm:mb-0"
    >
      <h2
        data-title
        class="title font-audiowide md:text-8xl sm:text-[80px] text-[55px] leading-tight text-main-color relative z-10"
      >
        NEWS
      </h2>
      <h4
        data-header-image
        class="header_img absolute bottom-3 left-1/2 transform -translate-x-1/2 z-0 font-audiowide text-main-color opacity-10 sm:text-[150px] text-8xl leading-none"
      >
        NEWS
      </h4>

      <span
        class="absolute bottom-0 block border-b left-10 right-10 sm:hidden border-main-color"
      ></span>
    </div>

    <div data-content class="content">
      <div
        class="flex items-center justify-between w-full max-w-screen-xl px-10 mx-auto lg:px-40 sm:px-20"
      >
        <main_link_btn class="md:min-w-[180px]" text="ALL NEWS" url="/News" />

        <div class="flex items-center justify-center">
          <button
            @click="PrevList"
            class="relative flex items-center justify-center w-8 h-8 bg-transparent"
            @mouseenter="button_hover = 'left'"
            @mouseleave="button_hover = ''"
          >
            <chevron_left class="text-2xl text-main-color" />

            <span
              class="absolute z-10 transition-all duration-300 -top-0 -left-0 transform-gpu"
              :class="
                button_hover == 'left'
                  ? '-translate-x-1 -translate-y-1'
                  : '-translate-x-0 -translate-y-0'
              "
            >
              <i class="block w-2 h-[1px] bg-main-color"></i>
              <i class="block w-[1px] h-2 bg-main-color"></i>
            </span>
            <span
              class="absolute top-0 right-0 z-10 flex flex-col items-end transition-all duration-300 transform-gpu"
              :class="
                button_hover == 'left'
                  ? 'translate-x-1 -translate-y-1'
                  : 'translate-x-0 -translate-y-0'
              "
            >
              <i class="block w-2 h-[1px] bg-main-color"></i>
              <i class="block w-[1px] h-2 bg-main-color"></i>
            </span>
            <span
              class="absolute z-10 transition-all duration-300 -bottom-0 -left-0 transform-gpu"
              :class="
                button_hover == 'left'
                  ? '-translate-x-1 translate-y-1'
                  : 'translate-x-0 -translate-y-0'
              "
            >
              <i class="block w-[1px] h-2 bg-main-color"></i>
              <i class="block w-2 h-[1px] bg-main-color"></i>
            </span>
            <span
              class="absolute z-10 flex flex-col items-end transition-all duration-300 -bottom-0 -right-0 transform-gpu"
              :class="
                button_hover == 'left'
                  ? 'translate-x-1 translate-y-1'
                  : 'translate-x-0 -translate-y-0'
              "
            >
              <i class="block w-[1px] h-2 bg-main-color"></i>
              <i class="block w-2 h-[1px] bg-main-color"></i>
            </span>
          </button>
          <button
            @click="NextList"
            class="relative flex items-center justify-center w-8 h-8 ml-6 bg-transparent"
            @mouseenter="button_hover = 'right'"
            @mouseleave="button_hover = ''"
          >
            <chevron_right class="text-2xl text-main-color" />

            <span
              class="absolute z-10 transition-all duration-300 -top-0 -left-0 transform-gpu"
              :class="
                button_hover == 'right'
                  ? '-translate-x-1 -translate-y-1'
                  : '-translate-x-0 -translate-y-0'
              "
            >
              <i class="block w-2 h-[1px] bg-main-color"></i>
              <i class="block w-[1px] h-2 bg-main-color"></i>
            </span>
            <span
              class="absolute top-0 right-0 z-10 flex flex-col items-end transition-all duration-300 transform-gpu"
              :class="
                button_hover == 'right'
                  ? 'translate-x-1 -translate-y-1'
                  : 'translate-x-0 -translate-y-0'
              "
            >
              <i class="block w-2 h-[1px] bg-main-color"></i>
              <i class="block w-[1px] h-2 bg-main-color"></i>
            </span>
            <span
              class="absolute z-10 transition-all duration-300 -bottom-0 -left-0 transform-gpu"
              :class="
                button_hover == 'right'
                  ? '-translate-x-1 translate-y-1'
                  : 'translate-x-0 -translate-y-0'
              "
            >
              <i class="block w-[1px] h-2 bg-main-color"></i>
              <i class="block w-2 h-[1px] bg-main-color"></i>
            </span>
            <span
              class="absolute z-10 flex flex-col items-end transition-all duration-300 -bottom-0 -right-0 transform-gpu"
              :class="
                button_hover == 'right'
                  ? 'translate-x-1 translate-y-1'
                  : 'translate-x-0 -translate-y-0'
              "
            >
              <i class="block w-[1px] h-2 bg-main-color"></i>
              <i class="block w-2 h-[1px] bg-main-color"></i>
            </span>
          </button>
        </div>
      </div>

      <div class="w-full mt-16">
        <ol data-carousel-box class="flex items-stretch w-full gsap_item">
          <li
            data-carousel
            v-for="(item, item_index) in page_data"
            :key="`news_list_${item.news_id}`"
            class="relative flex-shrink-0 w-11/12 px-4 transition-opacity duration-300 md:w-3/5 md:px-10 gsap_item"
          >
            <router-link
              :to="`/News/${item.news_id}`"
              class="relative block w-full overflow-hidden image_box aspect-h-9 aspect-w-16"
            >
              <img
                :src="item.cover_image"
                :alt="`Savage Bull ${item.title}`"
                class="block object-cover w-full h-full"
              />
              <span
                data-carousel-progress
                class="time_line absolute top-auto bottom-0 left-0 w-full h-1 bg-yellow-400 z-10 transform -translate-x-[105%]"
              ></span>
            </router-link>
            <div class="w-full mt-4 md:w-auto">
              <h3 class="font-bold text-black sm:text-xl">{{ item.title }}</h3>
              <div
                class="mb-2 text-xs tracking-widest text-black date_box font-audiowide"
              >
                {{ item.created_at.slice(0, 10) }}
              </div>

              <div
                class="relative z-10 flex items-center justify-end w-full pb-4 ml-auto border-b sm:w-2/3 border-main-color"
              >
                <main_link_btn
                  class="md:min-w-[180px] min-w-[140px] sm:text-base text-xs"
                  text="VIEW MORE"
                  :url="`/News/${item.news_id}`"
                />

                <span
                  class="absolute left-0 text-3xl transform -translate-y-1/2 font-audiowide text-main-color top-1/2"
                  >0{{ item_index + 1 }}</span
                >

                <span
                  class="absolute bottom-0 left-0 w-1 h-1 bg-main-color"
                ></span>
              </div>
            </div>
          </li>
          <li
            data-carousel
            v-for="(item, item_index) in page_data"
            :key="`news_list_1_${item.news_id}`"
            class="relative flex-shrink-0 w-11/12 px-4 transition-opacity duration-300 md:w-3/5 md:px-10 gsap_item"
          >
            <router-link
              :to="`/News/${item.news_id}`"
              class="relative block w-full overflow-hidden image_box aspect-h-9 aspect-w-16"
            >
              <img
                :src="item.cover_image"
                :alt="`Savage Bull ${item.title}`"
                class="block object-cover w-full h-full"
              />
              <span
                data-carousel-progress
                class="time_line absolute top-auto bottom-0 left-0 w-full h-1 bg-yellow-400 z-10 transform -translate-x-[105%]"
              ></span>
            </router-link>
            <div class="w-full mt-4 md:w-auto">
              <h3 class="font-bold text-black sm:text-xl">{{ item.title }}</h3>
              <div
                class="mb-2 text-xs tracking-widest text-black date_box font-audiowide"
              >
                {{ item.created_at.slice(0, 10) }}
              </div>

              <div
                class="relative z-10 flex items-center justify-end w-full pb-4 ml-auto border-b sm:w-2/3 border-main-color"
              >
                <main_link_btn
                  class="md:min-w-[180px] min-w-[140px] sm:text-base text-xs"
                  text="VIEW MORE"
                  :url="`/News/${item.news_id}`"
                />

                <span
                  class="absolute left-0 text-3xl transform -translate-y-1/2 font-audiowide text-main-color top-1/2"
                  >0{{ item_index + 1 }}</span
                >

                <span
                  class="absolute bottom-0 left-0 w-1 h-1 bg-main-color"
                ></span>
              </div>
            </div>
          </li>
        </ol>
      </div>
    </div>
  </section>
</template>

<script>
import chevron_left from '@/components/svg/chevron_left';
import chevron_right from '@/components/svg/chevron_right';
import main_link_btn from '@/components/ui/main_link_btn.vue';
import { news_animation } from '@/gsap/home/news';
export default {
  name: 'HomeNews',
  props: {
    page_data: {
      type: Array,
    },
  },
  components: {
    main_link_btn,
    chevron_left,
    chevron_right,
  },
  data() {
    return {
      button_hover: '',
      active_index: 0,
      news_animation: null,
    };
  },
  methods: {
    SetGsap() {
      this.news_animation = new news_animation(this.$refs.MainContent);
    },
    NextList() {
      this.news_animation.setNext();
    },
    PrevList() {
      this.news_animation.setPrev();
    },
  },
};
</script>
<style>
#HomeNews .image_box {
  -webkit-clip-path: polygon(100% 0, 100% 90%, 92% 100%, 0 100%, 0 0);
  clip-path: polygon(100% 0, 100% 90%, 92% 100%, 0 100%, 0 0);
}
</style>
