import Vue from "vue";
import Vuex from "vuex";

import { GetFooterData } from "@/api/footer";
import { GetHomePageData } from "@/api/home.js";
import { GetNewsList } from "@/api/news_list";
import { GetProductsData } from "@/api/products";
import { GetAboutPageData } from "@/api/about";
import { GetSEO } from "@/api/seo_meta";
import { GetDownloadFiles } from "@/api/download_center";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    main_menu: false,
    page_image_loaded: false,
    language_dialog: false,
    main_loading: 0,
    main_dialog: {
      msg: "",
      status: false,
    },
    image_dialog: {
      status: false,
      image: "",
    },
    footer_data: null,
    home_data: null,
    news_data: null,
    product_data: null,
    about_data: null,
    product_category_data: null,
    brand_list: [
      {
        id: "savage_bull",
        title: "野蠻公牛",
        sub_title: "SAVAGE BULL",
        bg_image: "/img/home/web_homepage_brand_sb_bg_lg.webp",
        logo_image: "/img/home/web_homepage_brand_sb_logo.webp",
        number: "01",
        link: "/Product?category=2",
      },
      {
        id: "speedy_rocket",
        title: "極速火箭",
        sub_title: "SPEEDY ROCKET",
        bg_image: "/img/home/web_homepage_brand_sr_bg_lg.webp",
        logo_image: "/img/home/web_homepage_brand_sr_logo.webp",
        number: "02",
        link: "/Product?category=4",
      },
      {
        id: "e_motor",
        title: "電動車專用",
        sub_title: "E-MOTO SERIES",
        bg_image: "/img/home/web_homepage_brand_emoto_bg_lg.webp",
        logo_image: "",
        number: "03",
        link: "/Product?category=6",
      },
      {
        id: "vespa",
        title: "偉士牌專用",
        sub_title: "VESPA SERIES",
        bg_image: "/img/home/web_homepage_brand_vps_bg_lg.webp",
        logo_image: "/img/home/web_homepage_brand_vps_logo.webp",
        number: "04",
        link: "/Product?category=8",
      },
      {
        id: "motorcycle",
        title: "重機專用",
        sub_title: "MOTORCYCLE SERIES",
        bg_image: "/img/home/web_homepage_brand_motorcycle_bg_lg.webp",
        logo_image: "",
        number: "05",
        link: "/Product?category=7",
      },
    ],
    seo_meta: null,
    download_files: null,
  },
  getters: {},
  mutations: {
    SetLanguageDialog(state, action) {
      state.language_dialog = action;
    },
    SetMainMenu(state, action) {
      state.main_menu = action;
      state.blur = action;
    },
    SetImageLoaded(state, action) {
      state.page_image_loaded = action;
    },
    SetLoading(state, action) {
      state.main_loading += action;
    },
    SetMainDialog(state, { msg, action }) {
      state.main_dialog.msg = msg;
      state.main_dialog.status = action;
    },
    SetImageModal(state, { status, image }) {
      state.image_dialog.status = status;
      state.image_dialog.image = image;
    },
    SetStateData(state, { key, val }) {
      state[key] = val;
    },
  },
  actions: {
    GetFooterData(state) {
      GetFooterData().then((res) => {
        state.commit("SetStateData", { key: "footer_data", val: res.data });
      });
    },
    GetHomePageData(state) {
      GetHomePageData().then((res) => {
        state.commit("SetStateData", { key: "home_data", val: res.data });
        state.commit("SetStateData", {
          key: "news_data",
          val: res.data.news_data,
        });
        state.commit("SetStateData", {
          key: "product_data",
          val: res.data.product_data,
        });
        state.commit("SetStateData", {
          key: "product_category_data",
          val: res.data.category,
        });
      });
    },
    GetNewsList(state) {
      GetNewsList().then((res) => {
        state.commit("SetStateData", { key: "news_data", val: res.data });
      });
    },
    GetProductsData(state) {
      GetProductsData().then((res) => {
        res.data.product_data = res.data.product_data.filter(
          (item) => item.available != "0"
        );
        state.commit("SetStateData", {
          key: "product_data",
          val: res.data.product_data,
        });
        res.data.category = res.data.category.filter(
          (item) => item.available != "0"
        );
        state.commit("SetStateData", {
          key: "product_category_data",
          val: res.data.category,
        });
      });
    },
    GetAboutPageData(state) {
      GetAboutPageData().then((res) => {
        state.commit("SetStateData", { key: "about_data", val: res.data });
      });
    },
    GetSEO(state) {
      return GetSEO().then((res) => {
        state.commit("SetStateData", { key: "seo_meta", val: res.data });
      });
    },
    GetDownloadFiles(state) {
      return GetDownloadFiles().then((res) => {
        state.commit("SetStateData", { key: "download_files", val: res.data });
      });
    },
  },
  modules: {},
});
