import { gsap } from '@/gsap/gsap_loader';

export class background_animation {
  constructor(el) {
    this.el = el;
    this.background = el.querySelector('[data-bg]');
    this.bg_card = this.background.querySelectorAll('[data-bg-card]');
    this.trigger_el = el.querySelector('[data-bg-trigger]');
    this.timeline = null;
    this.init();
  }

  init() {
    this.timeline != null ? this.timeline.kill() : '';
    this.timeline = gsap.timeline({
      scrollTrigger: {
        trigger: this.trigger_el,
        start: 'top center',
        toggleActions: 'restart play play reverse',
      },
    });

    this.timeline.fromTo(
      this.bg_card,
      {
        scaleY: 0,
        transformOrigin: 'top',
      },
      {
        scaleY: 2,
        transformOrigin: 'top',
        stagger: 0.001,
        duration: 1,
      },
      'same'
    );
  }
}
