<template>
  <section
    id="HomeVideo"
    class="relative z-10 w-full overflow-hidden aspect-w-16 aspect-h-9"
  >
    <video
      class="w-full"
      src="/img/home/永不止步，不斷創新Keep Moving-野蠻公牛.mp4"
      muted
      playsinline
      autoplay
      loop
    ></video>

    <div
      class="absolute top-0 left-0 right-0 bottom-0 z-[5] bg-black bg-opacity-60"
      style="background-image: url('/img/home/dot_cover.webp')"
    ></div>
    <svg
      class="w-2/3 max-w-[700px] absolute z-10 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 586.83 51.12"
    >
      <path
        class="cls-1"
        fill="white"
        d="M-582.65,169.37a16,16,0,0,1-.68,4.87,14.6,14.6,0,0,1-1.81,3.81,12.05,12.05,0,0,1-2.64,2.82,16.78,16.78,0,0,1-3.13,1.9,16.19,16.19,0,0,1-3.28,1.09,15.73,15.73,0,0,1-3.15.35H-632.6v-9.14h35.26a5.34,5.34,0,0,0,4.09-1.55,5.76,5.76,0,0,0,1.46-4.15,6.88,6.88,0,0,0-.38-2.32,5.48,5.48,0,0,0-1.11-1.83A4.94,4.94,0,0,0-595,164a6.08,6.08,0,0,0-2.3-.42h-21a16.27,16.27,0,0,1-4.79-.8,14.17,14.17,0,0,1-4.76-2.54,14.16,14.16,0,0,1-3.66-4.61,15.18,15.18,0,0,1-1.45-7,15,15,0,0,1,1.45-6.94,14.27,14.27,0,0,1,3.66-4.61,14.25,14.25,0,0,1,4.76-2.57,16.57,16.57,0,0,1,4.79-.79h31.11v9.14h-31.11a5.26,5.26,0,0,0-4.07,1.59,5.91,5.91,0,0,0-1.45,4.18,5.8,5.8,0,0,0,1.45,4.17,5.4,5.4,0,0,0,4.07,1.52h21.09a16.42,16.42,0,0,1,3.13.41,15.68,15.68,0,0,1,3.29,1.14,16.8,16.8,0,0,1,3.11,1.95,13.66,13.66,0,0,1,2.62,2.83,14.07,14.07,0,0,1,1.81,3.8A16.49,16.49,0,0,1-582.65,169.37Z"
        transform="translate(633.02 -133.79)"
      />
      <path
        class="cls-1"
        fill="white"
        d="M-525.59,184.21h-9.14V171.83h-32.13v12.38H-576V159a26.48,26.48,0,0,1,1.9-10.16,23.69,23.69,0,0,1,5.27-8,23.91,23.91,0,0,1,8-5.2,26.41,26.41,0,0,1,10.06-1.87h20.6a4.68,4.68,0,0,1,1.79.35,4.8,4.8,0,0,1,1.48,1,4.4,4.4,0,0,1,1,1.48,4.68,4.68,0,0,1,.35,1.79Zm-41.27-21.52h32.13V142.93h-16.06c-.29,0-.88,0-1.78.13a17.61,17.61,0,0,0-3.13.61,19.66,19.66,0,0,0-3.74,1.51,13,13,0,0,0-3.62,2.82,14.31,14.31,0,0,0-2.73,4.48,17.73,17.73,0,0,0-1.07,6.52Z"
        transform="translate(633.02 -133.79)"
      />
      <path
        class="cls-1"
        fill="white"
        d="M-464.52,133.79l-23.87,48.62a4.41,4.41,0,0,1-1.67,1.81,4.53,4.53,0,0,1-2.45.69,4.44,4.44,0,0,1-2.4-.69,4.65,4.65,0,0,1-1.71-1.81l-23.83-48.62h10.23l17.71,36.32,17.79-36.32Z"
        transform="translate(633.02 -133.79)"
      />
      <path
        class="cls-1"
        fill="white"
        d="M-411.19,184.21h-9.14V171.83h-32.13v12.38h-9.14V159a26.48,26.48,0,0,1,1.9-10.16,23.69,23.69,0,0,1,5.27-8,23.91,23.91,0,0,1,8-5.2,26.4,26.4,0,0,1,10.05-1.87h20.61a4.68,4.68,0,0,1,1.79.35,4.8,4.8,0,0,1,1.48,1,4.4,4.4,0,0,1,1,1.48,4.68,4.68,0,0,1,.35,1.79Zm-41.27-21.52h32.13V142.93H-436.4c-.28,0-.87,0-1.77.13a17.61,17.61,0,0,0-3.13.61,19.66,19.66,0,0,0-3.74,1.51,13.15,13.15,0,0,0-3.63,2.82,14.29,14.29,0,0,0-2.72,4.48,17.52,17.52,0,0,0-1.07,6.52Z"
        transform="translate(633.02 -133.79)"
      />
      <path
        class="cls-1"
        fill="white"
        d="M-350.83,179.6a4.59,4.59,0,0,1-.35,1.83,4.41,4.41,0,0,1-1,1.46,4.6,4.6,0,0,1-1.46,1,4.85,4.85,0,0,1-1.83.35h-33a13.54,13.54,0,0,1-2.72-.3A14.29,14.29,0,0,1-394,183a14,14,0,0,1-2.72-1.64,11.06,11.06,0,0,1-2.3-2.42,12,12,0,0,1-1.6-3.31,13.85,13.85,0,0,1-.6-4.23V146.62a13.54,13.54,0,0,1,.3-2.72A14.21,14.21,0,0,1-400,141a12.9,12.9,0,0,1,1.65-2.73,11.7,11.7,0,0,1,2.45-2.3,12.06,12.06,0,0,1,3.28-1.6,13.78,13.78,0,0,1,4.22-.6h37.16v9.14h-37.16a3.72,3.72,0,0,0-2.74.95,3.85,3.85,0,0,0-1,2.82v24.68a3.65,3.65,0,0,0,1,2.72,3.69,3.69,0,0,0,2.72,1H-360V163.61h-24.32v-9.22h28.86a4.67,4.67,0,0,1,1.83.37,4.55,4.55,0,0,1,1.46,1,4.59,4.59,0,0,1,1,1.45,4.45,4.45,0,0,1,.35,1.78Z"
        transform="translate(633.02 -133.79)"
      />
      <path
        class="cls-1"
        fill="white"
        d="M-295.74,184.21h-33a14.84,14.84,0,0,1-4.15-.67,12.32,12.32,0,0,1-4.17-2.2,12.46,12.46,0,0,1-3.2-4,12.84,12.84,0,0,1-1.28-6v-33a4.68,4.68,0,0,1,.35-1.79,4.69,4.69,0,0,1,1-1.48,4.49,4.49,0,0,1,1.46-1,4.77,4.77,0,0,1,1.83-.35h41.16v9.14h-36.63v28.45a3.74,3.74,0,0,0,.95,2.74,3.78,3.78,0,0,0,2.78.95h32.9Zm-4.11-20.6h-28.86v-9.22h28.86Z"
        transform="translate(633.02 -133.79)"
      />
      <path
        class="cls-1"
        fill="white"
        d="M-213.19,173.13a12.44,12.44,0,0,1-.58,3.62,10.63,10.63,0,0,1-1.9,3.59,10.65,10.65,0,0,1-3.43,2.76,10.94,10.94,0,0,1-5.16,1.11H-259a4.77,4.77,0,0,1-1.83-.35,4.6,4.6,0,0,1-1.46-1,4.6,4.6,0,0,1-1-1.46,4.77,4.77,0,0,1-.35-1.83V138.4a4.68,4.68,0,0,1,.35-1.79,4.69,4.69,0,0,1,1-1.48,4.49,4.49,0,0,1,1.46-1,4.77,4.77,0,0,1,1.83-.35h30.58a12.17,12.17,0,0,1,3.62.6,11.16,11.16,0,0,1,3.61,1.92,10.63,10.63,0,0,1,2.78,3.42,11.13,11.13,0,0,1,1.1,5.17v1.72a18.9,18.9,0,0,1-.81,5.23,17.25,17.25,0,0,1-2.6,5.29,16,16,0,0,1,2.9,2.28,14.56,14.56,0,0,1,2.38,3.1,16.33,16.33,0,0,1,1.61,4,18.61,18.61,0,0,1,.6,4.89Zm-9.14-1.75a8.8,8.8,0,0,0-.56-3.22,7.28,7.28,0,0,0-1.58-2.45,6.9,6.9,0,0,0-2.47-1.56,9.12,9.12,0,0,0-3.23-.54h-20.6v-9.22h16.49a9.16,9.16,0,0,0,3.23-.54,6.81,6.81,0,0,0,2.46-1.56,7,7,0,0,0,1.57-2.45,9,9,0,0,0,.54-3.22V144.9c0-1.31-.63-2-1.9-2h-26.05v32.14h30.17c.16,0,.36,0,.59,0a1.39,1.39,0,0,0,.64-.21,1.52,1.52,0,0,0,.49-.6,2.64,2.64,0,0,0,.21-1.16Z"
        transform="translate(633.02 -133.79)"
      />
      <path
        class="cls-1"
        fill="white"
        d="M-156.13,179.6a4.77,4.77,0,0,1-.35,1.83,4.34,4.34,0,0,1-1,1.46,4.76,4.76,0,0,1-1.47,1,4.73,4.73,0,0,1-1.8.35h-20.6a27.89,27.89,0,0,1-5.9-.64,26.15,26.15,0,0,1-5.79-1.95,25.22,25.22,0,0,1-5.25-3.35,22,22,0,0,1-4.31-4.84,23.3,23.3,0,0,1-2.9-6.4,28.66,28.66,0,0,1-1-8V133.79h9.14V159a17.49,17.49,0,0,0,1.07,6.54,14.3,14.3,0,0,0,2.72,4.46,13.11,13.11,0,0,0,3.62,2.82,19.74,19.74,0,0,0,3.75,1.51,17.44,17.44,0,0,0,3.13.61c.9.09,1.49.13,1.77.13h16.07V133.79h9.14Z"
        transform="translate(633.02 -133.79)"
      />
      <path
        class="cls-1"
        fill="white"
        d="M-99.21,184.21h-41.7a4.85,4.85,0,0,1-1.83-.35,4.45,4.45,0,0,1-1.45-1,4.44,4.44,0,0,1-1-1.46,4.59,4.59,0,0,1-.35-1.83V133.79h9.14v41.28h37.16Z"
        transform="translate(633.02 -133.79)"
      />
      <path
        class="cls-1"
        fill="white"
        d="M-46.2,184.21H-87.89a4.77,4.77,0,0,1-1.83-.35,4.6,4.6,0,0,1-1.46-1,4.6,4.6,0,0,1-1-1.46,4.77,4.77,0,0,1-.35-1.83V133.79h9.14v41.28H-46.2Z"
        transform="translate(633.02 -133.79)"
      />
    </svg>
  </section>
</template>

<script>
export default {
  name: 'HomeVideo',
};
</script>
