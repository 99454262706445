import gsap from 'gsap';

export class loading {
  constructor(el) {
    this.el = el;
    this.percent_rects = el.querySelectorAll('[data-percent-rect]');

    this.timeline = null;
  }
  init() {
    this.timeline != null ? this.timeline.kill() : '';
    this.timeline = gsap.timeline({
      repeat: -1,
    });
    this.timeline.fromTo(
      this.percent_rects,
      {
        fill: 'transparent',
        fillOpacity: '.6',
      },
      {
        fill: '#00C1B5',
        fillOpacity: '.6',
        duration: 0.2,
        stagger: 0.1,
      }
    );
  }
}
