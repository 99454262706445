<template>
  <div id="app" class="relative w-full overflow-x-hidden">
    <MainLoading />
    <ImageLoader />
    <MainDialog />
    <MainHeader v-if="$route.name != 'language_select'" @open-menu="OpenMenu" />
    <MainMenu ref="MainMenu" />
    <LanguageDialog />
    <template v-if="$store.state.seo_meta != null">
      <router-view />
    </template>
    <div v-if="$route.name != 'language_select'" class="relative z-0 w-full">
      <div
        class="w-full max-w-screen-xl mx-auto flex items-center justify-center md:h-[800px] h-[500px] relative z-10"
      >
        <ContactButton />

        <TopButton class="absolute bottom-10 right-10" />
      </div>
      <div
        class="absolute z-[1] top-0 left-0 right-0 bottom-0 bg-repeat opacity-10 mix-blend-multiply"
        style="
          background-image: url('/img/bg_texture.webp');
          background-size: 80px;
        "
      ></div>
    </div>

    <MainFooter />
  </div>
</template>

<script>
import ContactButton from "@/components/ui/contact_btn.vue";
import TopButton from "./components/ui/top_btn.vue";
import MainFooter from "@/components/MainFooter.vue";
import MainHeader from "@/components/MainHeader.vue";
import ImageLoader from "./components/ImageLoader.vue";
import MainLoading from "@/components/MainLoading.vue";
import MainMenu from "@/components/MainMenu.vue";
import MainDialog from "@/components/MainDialog.vue";
import LanguageDialog from "@/components/LanguageDialog.vue";
export default {
  name: "App",
  components: {
    ContactButton,
    TopButton,
    MainFooter,
    MainHeader,
    ImageLoader,
    MainLoading,
    MainMenu,
    MainDialog,
    LanguageDialog,
  },
  methods: {
    OpenMenu() {
      this.$refs.MainMenu.Open();
    },
  },
  watch: {
    $route(new_val, old_val) {
      if (new_val.name != old_val.name) {
        this.ResetLoadImage();
        this.$store.commit("SetImageLoaded", false);
      }
    },
  },
  created() {
    if (this.$store.state.seo_meta == null) {
      this.$store.dispatch("GetSEO");
    }
  },
};
</script>
<style src="@/assets/css/output.css"></style>
