<template>
  <section
    ref="MainContent"
    id="HomeMainProduct"
    class="relative z-10 w-full max-w-screen-xl px-10 pb-20 mx-auto lg:px-40 sm:px-20"
  >
    <div
      class="relative z-10 flex flex-wrap items-end justify-between pt-32 pb-10"
    >
      <h2
        data-title
        class="title font-audiowide md:text-8xl sm:text-[80px] text-[55px] leading-tight text-main-color relative z-10"
      >
        MAIN<br />PRODUCTS
      </h2>

      <img
        data-header-image
        class="absolute top-0 right-0 z-0 h-56 header_img"
        src="/img/home/main_products/web_homepage_bg_lg_3.webp"
      />
      <span
        data-header-line
        class="absolute bottom-0 left-0 right-0 border-b header_line border-main-color"
      ></span>
    </div>

    <div class="mt-8 bullets">
      <ol class="flex items-center justify-start">
        <li
          v-for="(item, item_index) in page_data"
          @click="NextCard(item_index)"
          :key="item.id"
          data-carousel-bullet
          class="w-10 h-2 mr-2 transition-colors duration-300 border cursor-pointer border-main-color"
        ></li>
      </ol>
    </div>
    <div class="w-full mt-8 overflow-hidden img_box aspect-w-16 aspect-h-9">
      <div
        data-image-box
        class="w-auto overflow-visible images whitespace-nowrap"
      >
        <img
          data-carousel-image
          class="inline-block object-cover w-full h-full"
          v-for="item in page_data"
          :key="item.id"
          :src="item.pc_image"
        />
      </div>
    </div>
    <div data-content class="overflow-hidden main_content">
      <div data-carousel-box class="flex items-stretch w-auto">
        <div
          data-carousel
          v-for="(item, item_index) in page_data"
          :key="`carousel_${item_index}`"
          class="flex-shrink-0 w-full"
        >
          <div class="flex items-start mt-10">
            <span
              data-carousel-number
              class="text-4xl number md:text-5xl font-audiowide"
              >0{{ item_index + 1 }}</span
            >
            <div class="ml-5 md:ml-10">
              <h3
                data-carousel-title
                class="text-2xl font-bold carousel_title md:text-4xl sm:text-3xl"
              >
                {{ item.title }}
              </h3>
              <div
                data-carousel-content
                class="carousel_content max-w-[500px] md:text-base text-sm mt-4 tracking-wider leading-relaxed"
                v-html="item.content"
              ></div>
            </div>
          </div>
          <div
            data-carousel-footer
            class="carousel_footer md:mt-5 mt-20 sm:max-w-[500px] sm:w-full w-5/6 flex items-center justify-end ml-auto border-b border-main-color pb-4 relative"
          >
            <main_link_btn
              class="min-w-[150px]"
              text="VIEW PRODUCT"
              :url="item.link"
            />

            <span class="absolute bottom-0 left-0 w-1 h-1 bg-main-color"></span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import main_link_btn from '@/components/ui/main_link_btn.vue';
import { home_main_product } from '@/gsap/home/main_product';
export default {
  name: 'HomeMainProduct',
  props: {
    page_data: {
      type: Array,
    },
  },
  data() {
    return {
      home_main_product_animation: null,
    };
  },
  methods: {
    NextCard(val) {
      this.home_main_product_animation.setNext(val);
    },
    SetGsap() {
      this.home_main_product_animation = new home_main_product(
        this.$refs.MainContent
      );
    },
  },
  components: {
    main_link_btn,
  },
};
</script>

<style>
#HomeMainProduct .img_box {
  -webkit-clip-path: polygon(100% 0, 100% 100%, 100% 100%, 8% 100%, 0 88%, 0 0);
  clip-path: polygon(100% 0, 100% 100%, 100% 100%, 8% 100%, 0 88%, 0 0);
}
</style>
