<template>
  <main ref="MainContent" class="relative w-full overflow-hidden home">
    <BreadCrumb :path="bread_crumb_path" />
    <template v-if="page_data != null">
      <Cover
        ref="HomeCover"
        :pc_cover="page_data.home_banner"
        :mb_cover="page_data.home_banner_mb"
      />
      <About ref="HomeAbout" :page_data="page_data.home_about" />
      <Brands ref="HomeBrand" :page_data="page_data.brands" />
      <MainProduct
        ref="HomeMainProduct"
        data-bg-trigger
        :page_data="page_data.carousel_data"
      />
      <VideoSection />
      <News ref="HomeNews" :page_data="page_data.news_data" />
    </template>

    <div data-bg class="fixed top-0 bottom-0 left-0 right-0 z-0 bg-main-color">
      <div
        class="absolute z-[1] top-0 left-0 right-0 bottom-0 bg-repeat opacity-10 mix-blend-multiply"
        style="
          background-image: url('/img/bg_texture.webp');
          background-size: 80px;
        "
      ></div>

      <div class="absolute top-0 bottom-0 left-0 right-0 z-0">
        <div
          v-for="item in 20"
          :key="`bg_el_${item}`"
          data-bg-card
          class="w-full h-[5%] bg-white scale-y-0 transform"
        ></div>
      </div>
    </div>
  </main>
</template>

<script>
import Cover from '@/components/home/cover/index.vue';
import About from '@/components/home/about/index.vue';
import Brands from '@/components/home/brands/index.vue';
import MainProduct from '@/components/home/main_product/index.vue';
import VideoSection from '@/components/home/video/index.vue';
import News from '@/components/home/news/index.vue';
import BreadCrumb from '@/components/BreadCrumb.vue';

import { GetMetaData } from '@/common/meta';
import { background_animation } from '@/gsap/home/background';

export default {
  name: 'HomeView',
  components: {
    Cover,
    About,
    Brands,
    MainProduct,
    VideoSection,
    News,
    BreadCrumb,
  },
  data() {
    return {
      bread_crumb_path: [
        {
          title: 'HOME',
          link: '/',
        },
      ],
      meta_data: null,
      background_animation: null,
    };
  },
  computed: {
    page_data() {
      return this.$store.state.home_data;
    },
    page_image_loaded() {
      return this.$store.state.page_image_loaded;
    },
  },
  methods: {
    CheckDataLoad() {
      if (this.page_data != null) {
        this.$nextTick(() => {
          this.LoadImage();
        });
      }
    },
    PageInit() {
      const component_list = [
        'HomeAbout',
        'HomeCover',
        'HomeBrand',
        'HomeMainProduct',
        'HomeNews',
      ];
      component_list.forEach((item) => {
        this.$refs[item].SetGsap();
      });
      this.background_animation = new background_animation(
        this.$refs.MainContent
      );
      this.$nextTick(() => {
        this.meta_data = GetMetaData();
        window.prerenderReady = true;
        window.dataLayer.push({
          event: 'page_view',
          page_title: this.meta_data.title,
        });
      });
    },
  },
  watch: {
    page_data() {
      this.page_data != null ? this.CheckDataLoad() : '';
    },
    page_image_loaded() {
      this.page_image_loaded ? this.PageInit() : '';
    },
  },
  created() {
    this.page_data == null
      ? this.$store.dispatch('GetHomePageData')
      : this.CheckDataLoad();
  },
  metaInfo() {
    return this.meta_data;
  },
};
</script>
