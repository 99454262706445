import { gsap } from '@/gsap/gsap_loader';
import router from '@/router/index';

export class news_animation {
  constructor(el) {
    this.el = el;
    this.title = el.querySelector('[data-title]');
    this.label = el.querySelector('[data-label-box]');
    this.label_text = el.querySelector('[data-label-text]');
    this.content = el.querySelectorAll('[data-content]');
    this.header_img = el.querySelector('[data-header-image]');

    // carousel
    this.active_index = 0;
    this.change_second = 5; //timer wait time
    this.carousel_box = el.querySelector('[data-carousel-box]');
    this.carousel = el.querySelectorAll('[data-carousel]');
    this.card_width = this.carousel[0].offsetWidth;
    this.back_distance = (window.innerWidth - this.card_width) / 2;
    this.start_pos =
      (this.carousel.length / 2) * this.card_width * -1 + this.back_distance;

    this.time_line = null;
    this.progress_time_line = null;
    this.carousel_time_line = null;
    this.carousel_timer = null;
    this.init();
  }

  init() {
    this.setHeader();
    this.setCarousel();
  }

  setHeader() {
    this.time_line != null ? this.time_line.kill() : '';
    this.time_line = gsap.timeline({
      scrollTrigger: {
        trigger: this.title,
        start: 'top 70%',
        toggleActions: 'restart play play reverse',
      },
    });
    this.time_line
      .fromTo(
        this.title,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 0.02,
          delay: 0.3,
          repeat: 10,
        },
        'same'
      )
      .fromTo(
        this.label,
        {
          scaleY: 0,
          transformOrigin: 'top',
        },
        {
          scaleY: 1,
          duration: 0.3,
          transformOrigin: 'top',
        },
        'same'
      )
      .fromTo(
        this.label_text,
        {
          y: '-110%',
        },
        {
          y: '0%',
          delay: 0.3,
        },
        'same'
      )
      .from(
        this.header_img,
        {
          opacity: 0,
          delay: 0.6,
        },
        'same'
      )
      .fromTo(
        this.content,
        {
          opacity: 0,
          y: '10%',
        },
        {
          opacity: 1,
          delay: 0.6,
          y: '0%',
        },
        'same'
      );
  }
  setCarousel() {
    gsap.set(this.carousel, {
      opacity: 0.3,
    });
    gsap.set(this.carousel[0], {
      opacity: 1,
    });
    gsap.set(this.carousel[this.carousel.length / 2], {
      opacity: 1,
    });

    gsap.set(this.carousel_box, {
      x: this.start_pos + 'px',
      onComplete: () => {
        this.setCarouselTimer();
      },
    });
  }
  setCarouselTimer() {
    this.setProgressLine();
    this.carousel_timer = setTimeout(() => {
      if (router.history.current.name == 'home') {
        this.setNext();
      }
    }, 5000);
  }
  setProgressLine() {
    const time_line = this.carousel[this.active_index].querySelector(
      '[data-carousel-progress]'
    );
    const time_line_2 = this.carousel[
      this.active_index + this.carousel.length / 2
    ].querySelector('[data-carousel-progress]');

    this.progress_time_line != null ? this.progress_time_line.kill() : '';
    this.progress_time_line = gsap.timeline({
      onComplete: () => {
        if (router.history.current.name == 'home') {
          gsap.set(time_line, {
            x: '-105%',
          });
          gsap.set(time_line_2, {
            x: '-105%',
          });
        }
      },
    });

    this.progress_time_line
      .fromTo(
        time_line,
        {
          x: '-105%',
        },
        {
          x: '0',
          duration: this.change_second,
        },
        'same'
      )
      .fromTo(
        time_line_2,
        {
          x: '-105%',
        },
        {
          x: '0',
          duration: this.change_second,
        },
        'same'
      );
  }
  clearProgressLine() {
    this.progress_time_line != null ? this.progress_time_line.kill() : '';
    const progress_line = this.el.querySelectorAll('[data-carousel-progress]');
    gsap.set(progress_line, {
      x: '-105%',
    });
  }

  setNext() {
    this.resetCarouselTimeline();
    if (this.active_index == this.carousel.length / 2 - 2) {
      this.carousel_time_line.set(this.carousel_box, {
        x: this.start_pos + this.card_width * 2 + 'px',
      });
      this.carousel_time_line.to(this.carousel_box, {
        x: this.start_pos + this.card_width * 1 + 'px',
        duration: 1,
      });
      this.active_index += 1;
    } else if (this.active_index == this.carousel.length / 2 - 1) {
      this.carousel_time_line.to(this.carousel_box, {
        x: this.start_pos + 'px',
        duration: 1,
      });
      this.active_index = 0;
    } else {
      this.carousel_time_line.to(this.carousel_box, {
        x: this.start_pos - this.card_width * (this.active_index + 1) + 'px',
        duration: 1,
      });
      this.active_index += 1;
    }
  }
  setPrev() {
    this.resetCarouselTimeline();
    if (this.active_index == 1) {
      this.carousel_time_line.set(this.carousel_box, {
        x: this.start_pos - this.card_width + 'px',
      });
      this.carousel_time_line.to(this.carousel_box, {
        x: this.start_pos + 'px',
        duration: 1,
      });
      this.active_index -= 1;
    } else {
      this.active_index == 0
        ? (this.active_index = this.carousel.length / 2 - 1)
        : (this.active_index = this.active_index - 1);
      this.carousel_time_line.to(this.carousel_box, {
        x:
          this.start_pos +
          this.card_width * (this.carousel.length / 2 - this.active_index) +
          'px',
        duration: 1,
      });
    }
  }
  setCarouselOpacity() {
    gsap.to(this.carousel[this.active_index], {
      opacity: 1,
    });
    gsap.to(this.carousel[this.active_index + this.carousel.length / 2], {
      opacity: 1,
    });
  }
  resetCarouselTimeline() {
    // reset
    this.carousel_timer != null ? clearTimeout(this.carousel_timer) : '';
    this.carousel_time_line != null ? this.carousel_time_line.kill() : '';
    this.clearProgressLine();
    gsap.to(this.carousel, {
      opacity: 0.3,
    });

    //timeline
    this.carousel_time_line = gsap.timeline({
      onStart: () => {
        this.setCarouselOpacity();
      },
      onComplete: () => {
        this.setCarouselTimer();
      },
    });
  }
}
