import { gsap } from '@/gsap/gsap_loader';

export class home_about_animation {
  constructor(el) {
    this.el = el;
    this.title = el.querySelector('[data-title]');
    this.label = el.querySelector('[data-label-box]');
    this.label_text = el.querySelector('[data-label-text]');
    this.content = el.querySelectorAll('[data-cotnent]');
    this.header_img = el.querySelector('[data-header-image]');

    this.timeline = null;
    this.init();
  }

  init() {
    this.timeline != null ? this.timeline.kill() : '';
    this.timeline = gsap.timeline({
      scrollTrigger: {
        trigger: this.title,
        start: 'top center',
        toggleActions: 'restart play play reverse',
      },
    });

    this.timeline
      .fromTo(
        this.title,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 0.02,
          delay: 0.3,
          repeat: 10,
        },
        'same'
      )
      .fromTo(
        this.label,
        {
          scaleY: 0,
          transformOrigin: 'top',
        },
        {
          scaleY: 1,
          duration: 0.3,
          transformOrigin: 'top',
        },
        'same'
      )
      .fromTo(
        this.label_text,
        {
          y: '-110%',
        },
        {
          y: '0%',
          delay: 0.3,
        },
        'same'
      )
      .fromTo(
        this.header_img,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          delay: 0.6,
        },
        'same'
      )
      .fromTo(
        this.content,
        {
          opacity: 0,
          y: '10%',
        },
        {
          opacity: 1,
          y: '0%',
          delay: 0.3,
        },
        'same'
      );
  }
}
