import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '../views/HomeView.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'language_select',
    component: () =>
      import(
        /* webpackChunkName: "language_select" */ '../views/LanguageSelectView.vue'
      ),
  },
  {
    path: '/Home',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/About',
    name: 'about',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  },
  {
    path: '/Download',
    name: 'download_center',
    component: () =>
      import(
        /* webpackChunkName: "download_center" */ '../views/DownloadCenter.vue'
      ),
  },
  {
    path: '/Contact',
    name: 'contact',
    component: () =>
      import(/* webpackChunkName: "contact" */ '../views/Contact.vue'),
  },
  {
    path: '/Product',
    name: 'product_list',
    component: () =>
      import(/* webpackChunkName: "product_list" */ '../views/ProductList.vue'),
  },
  {
    path: '/Product/:id',
    name: 'product',
    component: () =>
      import(/* webpackChunkName: "product" */ '../views/ProductPage.vue'),
  },
  {
    path: '/ColorSelector',
    name: 'color_selector',
    component: () =>
      import(
        /* webpackChunkName: "color_selector" */ '../views/CustomizeList.vue'
      ),
  },
  {
    path: '/ColorSelector/:id',
    name: 'customize',
    component: () =>
      import(/* webpackChunkName: "customize" */ '../views/Customize.vue'),
  },
  {
    path: '/News',
    name: 'news_list',
    component: () =>
      import(/* webpackChunkName: "news_list" */ '../views/NewsList.vue'),
  },
  {
    path: '/News/:id',
    name: 'news_page',
    component: () =>
      import(/* webpackChunkName: "news_page" */ '../views/NewsPage.vue'),
  },
  {
    path: '/ErrorPage',
    name: 'error_page',
    component: () =>
      import(/* webpackChunkName: "error_page" */ '../views/ErrorPage.vue'),
  },
  {
    path: '/index.php',
    redirect: '/',
  },
  {
    path: '*',
    redirect: '/ErrorPage',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    // always scroll to top
    return {
      top: 0,
    };
  },
});

export default router;
