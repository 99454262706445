<template>
  <div
    class="contact_btn relative cursor-pointer select-none"
    @mouseenter="hover = true"
    @mouseleave="hover = false"
    @click="$router.push('/Contact')"
  >
    <span
      class="
        absolute
        -top-2
        -left-2
        z-10
        transform-gpu
        transition-all
        duration-300
      "
      :class="
        hover
          ? 'translate-x-0 translate-y-0 opacity-100'
          : '-translate-x-4 -translate-y-4 opacity-0'
      "
    >
      <i class="block w-3 h-[2px] bg-black"></i>
      <i class="block w-[2px] h-2 bg-black"></i>
    </span>
    <span
      class="
        absolute
        -top-2
        -right-2
        z-10
        transform-gpu
        transition-all
        duration-300
        flex flex-col
        items-end
      "
      :class="
        hover
          ? 'translate-x-0 translate-y-0 opacity-100'
          : 'translate-x-4 -translate-y-4 opacity-0'
      "
    >
      <i class="block w-3 h-[2px] bg-black"></i>
      <i class="block w-[2px] h-2 bg-black"></i>
    </span>
    <span
      class="
        absolute
        -bottom-2
        -left-2
        z-10
        transform-gpu
        transition-all
        duration-300
      "
      :class="
        hover
          ? 'translate-x-0 translate-y-0 opacity-100'
          : '-translate-x-4 translate-y-4 opacity-0'
      "
    >
      <i class="block w-[2px] h-2 bg-black"></i>
      <i class="block w-3 h-[2px] bg-black"></i>
    </span>
    <span
      class="
        absolute
        -bottom-2
        -right-2
        z-10
        transform-gpu
        transition-all
        duration-300
        flex flex-col
        items-end
      "
      :class="
        hover
          ? 'translate-x-0 translate-y-0 opacity-100'
          : 'translate-x-4 translate-y-4 opacity-0'
      "
    >
      <i class="block w-[2px] h-2 bg-black"></i>
      <i class="block w-3 h-[2px] bg-black"></i>
    </span>
    <div class="overflow-hidden relative rounded-md px-10 py-2">
      <p
        class="
          font-audiowide
          w-full
          text-center
          lg:text-8xl
          sm:text-6xl
          text-4xl
          absolute
          z-10
          left-1/2
          -translate-x-1/2
          transition-transform
          transform-gpu
          duration-300
          text-main-color
        "
        :class="
          hover ? 'top-1/2 -translate-y-1/2' : '-top-1/2 -translate-y-[200%]'
        "
      >
        CONTACT US
      </p>
      <p
        :class="hover ? 'translate-y-[110%]' : 'translate-y-0'"
        class="
          font-audiowide
          lg:text-8xl
          sm:text-6xl
          text-4xl
          relative
          z-10
          transition-transform
          transform-gpu
          duration-300
        "
      >
        CONTACT US
      </p>

      <div
        class="
          absolute
          top-0
          left-0
          bottom-0
          right-0
          z-0
          bg-zinc-200
          origin-bottom
          transition-transform
          transform-gpu
          duration-300
        "
        :class="hover ? 'scale-y-0' : 'scale-y-1'"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactButton",
  data() {
    return {
      hover: false,
    };
  },
};
</script>