<template>
  <div
    data-card
    class="relative z-10 card"
    @mouseenter="$emit('mouseenter')"
    @mouseleave="$emit('mouseleave')"
  >
    <div class="relative w-full h-full">
      <div
        class="absolute z-10 text-center transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
      >
        <template v-if="logo_image != ''">
          <img
            data-card-logo
            width="192"
            height="192"
            class="mx-auto card_logo_image lg:w-48 sm:w-40 w-28"
            :alt="title"
            :src="logo_image"
          />
        </template>
        <h3
          data-card-title
          class="mt-2 text-2xl font-bold card_title sm:mt-6 sm:text-3xl font-audiowide"
        >
          {{ title }}
        </h3>
        <p
          data-card-subtitle
          class="mt-2 font-bold card_sub_title font-audiowide"
        >
          {{ sub_title }}
        </p>
      </div>
      <img
        data-card-background
        class="relative z-0 object-cover w-full h-full card_bg_image"
        :src="bg_image"
        :alt="title"
      />
    </div>
    <span
      data-card-number
      class="absolute text-2xl card_number font-audiowide bottom-10 left-6 sm:text-4xl"
      >{{ number }}</span
    >
    <main_link_btn
      data-card-button
      class="more_btn min-w-[150px] absolute -bottom-5 sm:left-6 left-10"
      text="PRODUCTS"
      theme="black"
      :url="link"
    />

    <span
      data-card-frame
      class="absolute z-10 transition-all duration-300 frame sm:-top-2 sm:-left-2 top-2 left-2 transform-gpu"
      :class="
        hover
          ? '-translate-x-2 -translate-y-2'
          : '-translate-x-0 -translate-y-0 '
      "
    >
      <i class="block w-3 sm:h-[1px] h-[2px] sm:bg-white bg-main-color"></i>
      <i class="block sm:w-[1px] w-[2px] h-2 sm:bg-white bg-main-color"></i>
    </span>
    <span
      data-card-frame
      class="absolute z-10 flex flex-col items-end transition-all duration-300 frame sm:-top-2 sm:-right-2 top-2 right-2 transform-gpu"
      :class="
        hover ? 'translate-x-2 -translate-y-2' : 'translate-x-0 -translate-y-0'
      "
    >
      <i class="block w-3 sm:h-[1px] h-[2px] sm:bg-white bg-main-color"></i>
      <i class="block sm:w-[1px] w-[2px] h-2 sm:bg-white bg-main-color"></i>
    </span>
    <span
      data-card-frame
      class="absolute z-10 transition-all duration-300 frame sm:-bottom-2 sm:-left-2 bottom-2 left-2 transform-gpu"
      :class="
        hover ? '-translate-x-2 translate-y-2' : '-translate-x-0 translate-y-0'
      "
    >
      <i class="block sm:w-[1px] w-[2px] h-2 sm:bg-white bg-main-color"></i>
      <i class="block w-3 sm:h-[1px] h-[2px] sm:bg-white bg-main-color"></i>
    </span>
    <span
      data-card-frame
      class="absolute z-10 flex flex-col items-end transition-all duration-300 frame sm:-bottom-2 sm:-right-2 bottom-2 right-2 transform-gpu"
      :class="
        hover ? 'translate-x-2 translate-y-2' : 'translate-x-0 translate-y-0'
      "
    >
      <i class="block sm:w-[1px] w-[2px] h-2 sm:bg-white bg-main-color"></i>
      <i class="block w-3 sm:h-[1px] h-[2px] sm:bg-white bg-main-color"></i>
    </span>
  </div>
</template>

<script>
import main_link_btn from '@/components/ui/main_link_btn.vue';
export default {
  name: 'HomeBrandCard',
  components: {
    main_link_btn,
  },
  props: {
    bg_image: {
      type: String,
      require: true,
    },
    logo_image: {
      type: String,
      require: true,
    },
    title: {
      type: String,
      require: true,
    },
    sub_title: {
      type: String,
      require: true,
    },
    link: {
      type: String,
      require: true,
    },
    number: {
      type: String,
      require: true,
    },
    card_height: {
      type: String,
      require: true,
    },
    hover: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
