import { gsap } from '@/gsap/gsap_loader';
import router from '@/router';

export class home_main_product {
  constructor(el) {
    this.el = el;
    // els
    this.title = el.querySelector('[data-title]');
    this.title_label = el.querySelector('[data-label-box]');
    this.title_label_text = el.querySelector('[data-label-text]');
    this.img_box = el.querySelectorAll('[data-image-box]');
    this.content = el.querySelectorAll('[data-content]');
    this.header_img = el.querySelector('[data-header-image]');
    this.header_line = el.querySelector('[data-header-line]');

    // carousel
    this.carousel_box = el.querySelector('[data-carousel-box]');
    this.carousel_cards = el.querySelectorAll('[data-carousel]');
    this.carousel_images = el.querySelectorAll('[data-carousel-image]');
    this.carousel_number = el.querySelectorAll('[data-carousel-number]');
    this.carousel_title = el.querySelectorAll('[data-carousel-title]');
    this.carousel_content = el.querySelectorAll('[data-carousel-content]');
    this.carousel_footer = el.querySelectorAll('[data-carousel-footer]');
    this.carousel_bullet = el.querySelectorAll('[data-carousel-bullet]');
    this.active_carousel = 0;
    this.carousel_timer = null;

    this.timeline = null;
    this.carousel_timeline = null;
    this.init();
  }

  init() {
    this.timeline != null ? this.timeline.kill() : '';
    this.timeline = gsap.timeline({
      scrollTrigger: {
        trigger: this.title,
        start: 'top 70%',
        toggleActions: 'restart play play reverse',
      },
    });

    gsap.set(this.carousel_bullet[0], {
      backgroundColor: '#00b9ac',
    });

    this.timeline
      .fromTo(
        this.title,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 0.02,
          delay: 0.3,
          repeat: 10,
        },
        'same'
      )
      .fromTo(
        this.title_label,
        {
          scaleY: 0,
          transformOrigin: 'top',
        },
        {
          scaleY: 1,
          duration: 0.3,
          transformOrigin: 'top',
        },
        'same'
      )
      .fromTo(
        this.header_line,
        {
          scaleX: 0,
          transformOrigin: 'left',
        },
        {
          scaleX: 1,
          duration: 0.3,
          delay: 0.2,
          transformOrigin: 'left',
        },
        'same'
      )
      .fromTo(
        this.title_label_text,
        {
          y: '-110%',
        },
        {
          y: '0%',
          delay: 0.3,
        },
        'same'
      )
      .fromTo(
        this.header_img,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          delay: 0.6,
        },
        'same'
      )
      .fromTo(
        this.content,
        {
          opacity: 0,
          y: '10%',
        },
        {
          opacity: 1,
          y: '0%',
          delay: 0.6,
        },
        0
      )
      .fromTo(
        this.img_box,
        {
          opacity: 0,
          y: '10%',
        },
        {
          opacity: 1,
          y: '0%',
          delay: 0.6,
          onComplete: () => {
            this.setCarouselTimer();
          },
        },
        0
      );
  }

  setCarouselTimer() {
    let next_num =
      this.active_carousel == this.carousel_cards.length - 1
        ? 0
        : this.active_carousel + 1;

    this.carousel_timer != null ? clearTimeout(this.carousel_timer) : '';
    this.carousel_timer = setTimeout(() => {
      if (router.history.current.name == 'home') {
        this.setNext(next_num);
      }
    }, 5000);
  }

  setNext(val) {
    if (router.history.current.name != 'home') {
      return false;
    }
    if (val != this.active_carousel) {
      clearTimeout(this.carousel_timer);
      // hide els
      this.carousel_timeline != null ? this.carousel_timeline.kill() : '';
      this.carousel_timeline = gsap.timeline();
      this.active_carousel = val;
      gsap.to(this.carousel_bullet, {
        backgroundColor: 'transparent',
      });
      gsap.to(this.carousel_bullet[val], {
        backgroundColor: '#00b9ac',
      });

      this.carousel_timeline
        .to(
          this.carousel_number,
          {
            x: '-10%',
            opacity: 0,
          },
          'hide'
        )
        .to(
          this.carousel_title,
          {
            y: '10%',
            opacity: 0,
          },
          'hide'
        )
        .to(
          this.carousel_content,
          {
            y: '10%',
            opacity: 0,
          },
          'hide'
        )
        .to(
          this.carousel_footer,
          {
            y: '10%',
            opacity: 0,
          },
          'hide'
        )
        // set active carousel
        .set(
          this.carousel_box,
          {
            x: val * -100 + '%',
          },
          'show'
        )
        .to(
          this.img_box,
          {
            x: val * -100 + '%',
          },
          'show'
        )
        .to(
          this.carousel_number[val],
          {
            x: '0%',
            opacity: 1,
          },
          'show'
        )
        .to(
          this.carousel_title[val],
          {
            y: '0%',
            delay: 0.4,
            opacity: 1,
          },
          'show'
        )
        .to(
          this.carousel_content[val],
          {
            y: '0%',
            delay: 0.4,
            opacity: 1,
          },
          'show'
        )
        .to(
          this.carousel_footer[val],
          {
            y: '0%',
            opacity: 1,
            onComplete: () => {
              this.setCarouselTimer();
            },
          },
          'show'
        );
    }
  }
}
