import { gsap } from '@/gsap/gsap_loader';

export class dialog {
  constructor(el) {
    this.el = el;
    this.msg = el.querySelector('[data-dialog-msg]');
    this.close_btn = el.querySelector('[data-dialog-button]');
    this.bg_el = el.querySelectorAll('[data-dialog-background]');
    this.content = el.querySelector('[data-dialog-box]');

    this.timeline = null;
  }

  open() {
    this.timeline != null ? this.timeline.kill() : '';
    this.timeline = gsap.timeline();
    this.timeline
      .set(
        this.el,
        {
          left: '0%',
        },
        'first'
      )
      .fromTo(
        this.el,
        {
          opacity: 0,
        },
        {
          opacity: 1,
        },
        'first'
      )
      .fromTo(
        this.content,
        {
          scale: 0,
          opacity: 1,
        },
        {
          scale: 1,
          opacity: 1,
          duration: 0.3,
          delay: 0.3,
        },
        'first'
      )
      .fromTo(
        this.bg_el[0],
        {
          opacity: 0,
        },
        {
          opacity: 1,
          delay: 0.5,
        },
        'first'
      )
      .fromTo(
        this.bg_el[1],
        {
          opacity: 0,
        },
        {
          opacity: 0.3,
          delay: 0.5,
        },
        'first'
      )
      .fromTo(
        this.bg_el[2],
        {
          opacity: 0,
        },
        {
          opacity: 0.3,
          delay: 0.5,
        },
        'first'
      )
      .fromTo(
        this.bg_el[3],
        {
          opacity: 0,
        },
        {
          opacity: 0.3,
          delay: 0.5,
        },
        'first'
      )
      .fromTo(
        this.msg,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          delay: 0.7,
        },
        'first'
      )
      .fromTo(
        this.close_btn,
        {
          opacity: 0,
          y: '10%',
        },
        {
          opacity: 1,
          y: '0%',
          delay: 0.7,
        },
        'first'
      );
  }

  close() {
    this.timeline != null ? this.timeline.kill() : '';
    this.timeline = gsap.timeline();
    this.timeline = gsap.timeline();
    this.timeline
      .to(
        this.el,
        {
          opacity: 0,
          delay: 0.3,
        },
        'first'
      )
      .fromTo(
        this.content,
        {
          scale: 1,
        },
        {
          scale: 0,
          duration: 0.3,
          delay: 0.3,
        },
        'first'
      )
      .to(
        this.bg_el,
        {
          opacity: 0,
        },
        'first'
      )
      .fromTo(
        this.msg,
        {
          opacity: 1,
        },
        {
          opacity: 0,
        },
        'first'
      )
      .fromTo(
        this.close_btn,
        {
          opacity: 1,
          y: '10%',
        },
        {
          opacity: 0,
          y: '0%',
        },
        'first'
      )
      .set(
        this.el,
        {
          left: '100%',
        },
        'second'
      );
  }
}
