<template>
  <div
    data-label-box
    class="title_label sm:min-w-[200px] min-w-[150px] sm:text-xl sm:px-10 px-4 py-2"
    :class="{ light: light }"
  >
    <span class="absolute -top-[2px] -left-[2px]">
      <i
        class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[1px] h-[5px] bg-main-color block"
      ></i>
      <i
        class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[5px] h-[1px] bg-main-color block"
      ></i>
    </span>
    <span class="absolute -top-[2px] -right-[2px]">
      <i
        class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[1px] h-[5px] bg-main-color block"
      ></i>
      <i
        class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[5px] h-[1px] bg-main-color block"
      ></i>
    </span>
    <span class="absolute -bottom-[2px] -left-[2px]">
      <i
        class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[1px] h-[5px] bg-main-color block"
      ></i>
      <i
        class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[5px] h-[1px] bg-main-color block"
      ></i>
    </span>
    <span class="absolute -bottom-[2px] -right-[2px]">
      <i
        class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[1px] h-[5px] bg-main-color block"
      ></i>
      <i
        class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[5px] h-[1px] bg-main-color block"
      ></i>
    </span>

    <p class="overflow-hidden">
      <span data-label-text class="block font-audiowide">{{ text }}</span>
    </p>
  </div>
</template>

<script>
export default {
  name: 'TitleLabel',
  props: {
    text: {
      type: String,
      default: '',
    },
    light: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
