import { gsap } from '@/gsap/gsap_loader';

export class home_brand {
  constructor(el) {
    this.el = el;
    // header
    this.title = el.querySelector('[data-title]');
    this.label = el.querySelector('[data-label-box]');
    this.label_text = el.querySelector('[data-label-text]');
    this.header_timeline = null;

    // cards
    this.cards = el.querySelectorAll('[data-card]');
    this.card_tweens = [];

    this.init();
    this.setCards();
  }

  init() {
    this.header_timeline != null ? this.header_timeline.kill() : '';
    this.header_timeline = gsap.timeline({
      scrollTrigger: {
        trigger: this.title,
        start: 'top 70%',
        toggleActions: 'restart play play reverse',
      },
    });

    this.header_timeline
      .fromTo(
        this.title,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 0.02,
          delay: 0.3,
          repeat: 10,
        },
        'same'
      )
      .fromTo(
        this.label,
        {
          scaleY: 0,
          transformOrigin: 'top',
        },
        {
          scaleY: 1,
          duration: 0.3,
          transformOrigin: 'top',
        },
        'same'
      )
      .fromTo(
        this.label_text,
        {
          y: '-110%',
        },
        {
          y: '0%',
          delay: 0.3,
        },
        'same'
      );
  }

  setCards() {
    this.clearCardTweens();
    this.cards.forEach((card) => {
      const bg_image = card.querySelectorAll('[data-card-background]');
      const logo_image = card.querySelectorAll('[data-card-logo]');
      const card_title = card.querySelectorAll('[data-card-title]');
      const card_sub_title = card.querySelectorAll('[data-card-subtitle]');
      const card_number = card.querySelectorAll('[data-card-number]');
      const more_btn = card.querySelectorAll('[data-card-button]');
      const frame = card.querySelectorAll('[data-card-frame]');

      let card_tl = gsap.timeline({
        scrollTrigger: {
          trigger: card,
          start: 'top 70%',
          toggleActions: 'restart play play reverse',
        },
      });

      card_tl
        .fromTo(
          bg_image,
          {
            scale: 0,
            transformOrigin: 'center',
          },
          {
            scale: 1,
            transformOrigin: 'center',
          },
          'same'
        )
        .fromTo(
          frame,
          {
            scale: 0,
          },
          {
            scale: 1,
            delay: 0.4,
          },
          'same'
        )
        .fromTo(
          card_title,
          {
            opacity: 0,
            y: '10%',
          },
          {
            opacity: 1,
            y: '0%',
            delay: 0.6,
          },
          'same'
        )
        .fromTo(
          card_sub_title,
          {
            opacity: 0,
            y: '10%',
          },
          {
            opacity: 1,
            y: '0%',
            delay: 0.6,
          },
          'same'
        )
        .fromTo(
          card_number,
          {
            opacity: 0,
            x: '-10%',
          },
          {
            opacity: 1,
            delay: 0.8,
            x: '0%',
          },
          'same'
        )
        .fromTo(
          more_btn,
          {
            opacity: 0,
            x: '-10%',
          },
          {
            opacity: 1,
            delay: 0.8,
            x: '0%',
          },
          'same'
        );

      if (logo_image) {
        card_tl.fromTo(
          logo_image,
          {
            opacity: 0,
          },
          {
            opacity: 1,
            duration: 0.02,
            delay: 0.4,
            repeat: 10,
          },
          'same'
        );
      }

      this.card_tweens.push(card_tl);
    });
  }

  clearCardTweens() {
    while (this.card_tweens.length) {
      let tween = this.card_tweens.pop();
      tween.kill();
      tween = null;
    }
  }
}
