import { gsap } from '@/gsap/gsap_loader';

export class marquee {
  constructor(el) {
    this.el = el;
    this.marquee_item = this.el.querySelectorAll('[data-marquee-item]');
    this.timeline = null;
    this.init();
  }
  init() {
    this.timeline != null ? this.timeline.kill() : '';
    this.timeline = gsap.timeline();
    const { duration, direction } = this.el.dataset;
    this.timeline.fromTo(
      this.marquee_item,
      {
        x: direction == 'right' ? '-100%' : '0%',
      },
      {
        x: direction == 'right' ? '0%' : '-100%',
        duration: duration || 40,
        ease: 'linear',
        repeat: -1,
      }
    );
  }
}
