export default {
  methods: {
    ReturnErrorPage() {
      var meta = document.createElement('meta');
      meta.setAttribute('property', 'prerender-status-code');
      meta.setAttribute('name', 'prerender-status-code');
      meta.content = '404';
      document.getElementsByTagName('head')[0].appendChild(meta);
      this.$router.push('/error_page');
      this.meta_title = 'PAGE NOT FOUND | SavageBull';
      this.meta_content = 'Page not found';
      this.meta_cover = window.location.origin + '/img/share/index.jpg';
      // window.prerenderReady = true;
    },
  },
};
